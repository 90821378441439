const state = {
  cart: [],
  price__name: {
    6: {
      0.5: 1.5,
      1: 2.5,
      1.5: 3,
    },
    8: {
      0.5: 2.5,
      1: 3,
      1.5: 3.5,
      2: 4,
    },
    10: {
      0.5: 3,
      1: 4.5,
      1.5: 5,
      2: 5.5,
    },
  },
  price__cadre: {
    0: 28,
    2: 24,
    3: 19,
    4: 16,
    6: 15,
    8: 14,
  },
  price__mininame: {
    0: 6,
    2: 5,
    4: 4,
    6: 3.7,
    10: 3.5,
    15: 3.3,
    20: 3.2,
    25: 3.1,
    30: 3,
  },
  price__infinite: {
    20: 24,
    25: 29,
    30: 34,
  },
  price__custom__frame: {
    0: 19,
    2: 18,
    3: 17,
    4: 15,
    6: 14,
    8: 13,
  },
  price__custom__horizontal__frame: {
    0: 24,
    2: 22,
    3: 19,
    4: 16,
    6: 15,
    8: 14,
  },
  price__simple_cadre: {
    0: 24,
    2: 22,
    3: 19,
    4: 16,
    6: 15,
    8: 14,
  },
  price__mini_planter: {
    0: 19,
    3: 16,
    6: 15,
    8: 14,
  },
  price__mini_planter_pregnancy: {
    0: 13,
    3: 11.5,
    6: 10.5,
    8: 10,
  },
  price__mini_planter_pictures: {
    0: 9.5,
    3: 8,
    6: 7,
    8: 6.5,
  },
  price__mini_planter_thanks: {
    0: 14.5,
    3: 12,
    6: 11,
    8: 10.5,
  },
  price__wedding_frame: {
    0: 19,
    2: 18,
    3: 17,
    4: 15,
    6: 14,
    8: 13,
  },
  price__cadre_rainbow: {
    0: 29.50,
    2: 27,
    3: 24,
    4: 21,
    6: 19,
    8: 17,
  },
};

const getters = {
  cart(state) {
    const hasCart = state.cart !== null && state.cart.length > 0;

    const cart = hasCart
      ? state.cart
      : JSON.parse(localStorage.getItem('cart'));

    return cart !== null ? cart : [];
  },
  orders: state => state.orders,
  priceName: state => state.price__name,
  priceCadre: state => state.price__cadre,
  priceSimpleCadre: state => state.price__simple_cadre,
  priceMiniName: state => state.price__mininame,
  priceInfinite: state => state.price__infinite,
  priceCadreFlora: state => state.price__custom__frame,
  priceCadreTipi: state => state.price__custom__frame,
  priceCadreBaptismClassic: state => state.price__custom__frame,
  priceCustomFrame: state => state.price__custom__frame,
  priceCustomHorizontalFrame: state => state.price__custom__horizontal__frame,
  priceFramePhrase: state => state.price__custom__frame,
  priceMiniGiftFramePhrase: state => state.price__mini__gift__frame,
  priceMiniPlanter: state => state.price__mini_planter,
  priceMiniPlanterPregnancy: state => state.price__mini_planter_pregnancy,
  priceMiniPlanterPictures: state => state.price__mini_planter_pictures,
  priceMiniPlanterThanks: state => state.price__mini_planter_thanks,
  priceWeddingFrame: state => state.price__wedding_frame,
  priceRainbowFrame: state => state.price__cadre_rainbow,
};

const computedPrice = (type, index) => {
  let key = 0;

  const basePrice = getters[type](state);

  Object.keys(basePrice).map(x => {
    if (x <= state.cart[index].quantity) key = x;
  });

  return basePrice[key];
};

function priceByType({ hiddenType, price }, index) {
  let internalPrice = 0;

  switch (hiddenType) {
    case 'frame':
    case 'baptism-godparents':
      internalPrice = computedPrice('priceCadre', index);
      break;
    case 'flora-frame':
      internalPrice = computedPrice('priceCadreFlora', index);
      break;
    case 'tipi-frame':
      internalPrice = computedPrice('priceCadreTipi', index);
      break;
    case 'mini-name':
      internalPrice = computedPrice('priceMiniName', index);
      break;
    case 'baptism-classic':
      internalPrice = computedPrice('priceCadreBaptismClassic', index);
      break;
    case 'custom-vertical-frame':
      internalPrice = computedPrice('priceCustomFrame', index);
      break;
    case 'custom-horizontal-frame':
      internalPrice = computedPrice('priceCustomHorizontalFrame', index);
      break;
    case 'frame-phrase':
      internalPrice = computedPrice('priceFramePhrase', index);
      break;
    case 'mini-planter':
      internalPrice = computedPrice('priceMiniPlanter', index);
      break;
    case 'mini-planter-pregnancy':
      internalPrice = computedPrice('priceMiniPlanterPregnancy', index);
      break;
    case 'mini-planter-pictures':
      internalPrice = computedPrice('priceMiniPlanterPictures', index);
      break;
    case 'mini-planter-thanks':
      internalPrice = computedPrice('priceMiniPlanterThanks', index);
      break;
    case 'cadre-de-mariage':
      internalPrice = computedPrice('priceWeddingFrame', index);
      break;
    case 'rainbow-frame':
      internalPrice = computedPrice('priceRainbowFrame', index);
      break;
    default:
      internalPrice = price;
      break;
  }

  return internalPrice;
}

const mutations = {
  addToCart(state, value) {
    if (state.cart === null) {
      state.cart = [];
    }
    state.cart.push(value);
    localStorage.setItem('cart', JSON.stringify(state.cart));
  },
  removeFromCart(state, id) {
    state.cart.splice(id, 1);
    localStorage.setItem('cart', JSON.stringify(state.cart));
  },
  emptyCart(state) {
    state.cart = [];
    localStorage.removeItem('cart');
  },
  setCart(state) {
    if (
      state.cart.length === 0 &&
      localStorage.getItem('cart') !== 'undefined'
    ) {
      state.cart = JSON.parse(localStorage.getItem('cart'));
      return;
    }

    state.cart = [];
  },
  addQty(state, value) {
    state.cart[value.index].quantity += 1;

    const price = priceByType(state.cart[value.index], value.index);

    state.cart[value.index].price = price;

    let total = state.cart[value.index].quantity * price;

    if (state.cart[value.index].options.length > 0) {
      Object.keys(state.cart[value.index].options).forEach(option => {
        total +=
          state.cart[value.index].quantity *
          state.cart[value.index].options[option].price;
      });
    }

    state.cart[value.index].totalPrice = total;

    localStorage.setItem('cart', JSON.stringify(state.cart));
  },
  removeQty(state, value) {
    if (state.cart[value.index].quantity > 1) {
      state.cart[value.index].quantity -= 1;

      const price = priceByType(state.cart[value.index], value.index);

      state.cart[value.index].price = price;

      let total = state.cart[value.index].quantity * price;

      if (state.cart[value.index].options.length > 0) {
        Object.keys(state.cart[value.index].options).forEach(option => {
          total +=
            state.cart[value.index].quantity *
            state.cart[value.index].options[option].price;
        });
      }

      state.cart[value.index].totalPrice = total;

      localStorage.setItem('cart', JSON.stringify(state.cart));
    }
  },
  updateQty(state, value) {
    if (value.qty !== '' && value.qty !== undefined && value.qty > 0) {
      state.cart[value.index].quantity = parseInt(value.qty, 10);

      const price = priceByType(state.cart[value.index], value.index);

      state.cart[value.index].price = price;
      state.cart[value.index].totalPrice =
        state.cart[value.index].quantity * price;

      localStorage.setItem('cart', JSON.stringify(state.cart));
    }
  },
};
const actions = {
  addCart({ commit }, value) {
    commit('addToCart', value);
  },
  removeCart({ commit }, id) {
    commit('removeFromCart', id);
  },
  emptyCart({ commit }) {
    commit('emptyCart');
  },
  qtyCart({ commit }, value) {
    commit(value.type, value);
  },
  setCart({ commit }) {
    commit('setCart');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
