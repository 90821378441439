import Vue from 'vue';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import ClickOutside from './directives/click-outside';

import './assets/scss/app.scss';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    debug: process.env.NODE_ENV === 'development',
    environment: process.env.VUE_APP_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

Vue.directive('click-outside', ClickOutside);

Vue.config.productionTip = false;

if (parseInt(localStorage.getItem('cookie'), 10)) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: 'UA-136711888-1',
      },
    },
    router,
  );
}

function checkVersion() {
  const { getters, commit } = store;

  axios
    .get(`${getters['app/baseurl']}/check`)
    .then(response => {
      const version = localStorage.getItem('version');

      if (version && response && version !== response.data.version) {
        commit('app/setNewVersion', response.data.version);
      }

      commit('app/setVersion', response.data.version);
    })
    .catch(err => {
      console.log(err);
    });
}

checkVersion();
setInterval(() => {
  checkVersion();
}, 60000);

new Vue({
  router,
  store,
  beforeCreate() {
    store.commit('shop/setCart');
  },
  render: h => h(App),
}).$mount('#app');
