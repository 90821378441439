const state = {
  isLoading: true,
  auth: {},
  orders: [],
  source: null,
  coupon: {},
  toaster: [],
  newVersion: null,
  env: process.env.VUE_APP_ENV,
  url: process.env.VUE_APP_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  cmsUrl: process.env.VUE_APP_CMS_URL,
};

const getters = {
  auth(state) {
    const auth = (state.auth.uid && state.auth.uid !== '') ? state.auth : JSON.parse(localStorage.getItem('auth'));
    return auth !== null ? auth : { uid: '', email: '' };
  },
  source(state) {
    const source = state.source !== null ? state.source : JSON.parse(localStorage.getItem('source'));
    return source;
  },
  isAdmin(state) {
    return getters.auth(state).uid !== '' && (getters.auth(state).uid === 'ozTImZIxNOfPeiIEoeO2e0xOxyE2' || getters.auth(state).uid === 'LfMuaD64djQpuxkX3ubkKqYBU7y1');
  },
  orders: state => state.orders,
  baseurl: state => state.apiUrl,
  env: state => state.env,
  url: state => state.url,
  version: () => localStorage.getItem('version') || '0',
  coupon: state => state.coupon,
  getToaster: state => state.toaster,
  getNewVersion: state => state.newVersion,
  cms: state => state.cmsUrl,
};

const mutations = {

  setListOrders(state, value) {
    state.orders = [];
    value.forEach((order) => {
      state.orders.push(order);
    });
  },
  updateOrder(state, value) {
    state.orders.splice(value.index, 1, value.order);
  },
  loginUser(state, value) {
    state.auth = {
      uid: value.uid,
      email: value.email,
    };

    localStorage.setItem('auth', JSON.stringify(value));
  },
  logoutUser(state) {
    state.auth = { uid: '', email: '' };
    localStorage.removeItem('auth');
  },
  setAuth(state) {
    if (state.auth === '' && localStorage.getItem('auth') !== 'undefined') {
      state.auth = JSON.parse(localStorage.getItem('auth'));
    } else {
      state.auth = { uid: '', email: '' };
    }
  },

  setSource(state, value) {
    state.source = value;
    localStorage.setItem('source', JSON.stringify(value));
  },

  clearSource(state) {
    state.source = null;
    localStorage.removeItem('source');
  },

  setVersion(state, value) {
    localStorage.setItem('version', value);
  },

  setNewVersion(state, value) {
    state.newVersion = value;
  },

  setCoupon(state, value) {
    state.coupon = { ...value };
  },

  resetCoupon(state) {
    state.coupon = {};
  },

  handleToaster: (state, toast) => {
    state.toaster.push(toast);
  },

  clearToaster: (state, time) => {
    state.toaster = state.toaster.filter(e => e.time !== time);
  },

  clearToasters: (state) => {
    state.toaster = [];
  },
};
const actions = {
  listOrders({ commit }, value) {
    commit('setListOrders', value);
  },
  updateOrder({ commit }, value) {
    commit('updateOrder', value);
  },
  qtyCart({ commit }, value) {
    commit(value.type, value);
  },
  setCart({ commit }) {
    commit('setCart');
  },
  setAuth({ commit }) {
    commit('setAuth');
  },
  loginUser({ commit }, value) {
    commit('loginUser', value);
  },
  logoutUser({ commit }) {
    commit('logoutUser');
  },
  setSource({ commit }, value) {
    commit('setSource', value);
  },
  clearSource({ commit }) {
    commit('clearSource');
  },
  setVersion({ commit }, value) {
    commit('setVersion', value);
  },
  setCoupon({ commit }, value) {
    commit('setCoupon', value);
  },
  resetCoupon({ commit }) {
    commit('resetCoupon');
  },
  setToaster: ({ commit }, toast) => {
    commit('handleToaster', toast);
  },
  clearToaster: ({ commit }, time) => {
    commit('clearToaster', time);
  },
  clearToasters: ({ commit }) => {
    commit('clearToasters');
  },
  setNewVersion: ({ commit }, value) => {
    commit('setNewVersion', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
